html, body
{
  height: 100%;
}

header, nav, article, footer, address {
   display: block;
}

svg
{
  background-color: #ffffff;
}

.hidden
{
  display: none !important;
}

.last_n_hidden
{
  display: none !important;
}

header {
  position: absolute;
  top:0px;
  left: 0px;
  width:100%;
  text-align:center;
  font-size: 20pt;
  z-index: 10;
}

header div
{
  display: inline-block;
}

header a{
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

header #display_options_menu{
  position: absolute;
  width:200px;
  right:10px;
  top:0px;
  background-color: #FFFFFF;
}

header #display_options_menu div{
  display: block;
}

header #display_options_menu div.display_options_menu_spacer {
  display: block;
  height:10px;
}

header #display_options_menu div.selected
{
  background-color: #000000;
  color: #FFFFFF;
}

header #display_options div#home_away_select {
  border: 1px solid black;
}

header #display_options div#home_away_select.sort_selected {
  border: 1px solid #990000;
}

header #display_options div.sort_selected {
  border: 1px solid #990000;
}

header #display_options div#home_select.sort_selected,
header #display_options div#away_select.sort_selected {
  border: 1px solid #990000;
  width: calc(50% - 2px);
}

header #display_options div#home_away_select div{
  display:inline-block;
  width: 50%;
}

header #display_options div#league_level_select {
  border: 1px solid black;
}

header #key_sub_menu .key_item {
  font-size:12px;
}

.home_incomplete,.away_incomplete
{
  background-color: #999999;
}

.home_incomplete.game_behind_first,.away_incomplete.game_behind_first,
.home_incomplete.game_behind_first_limited,.away_incomplete.game_behind_first_limited
{
  background-color: #4C3A3A;
}

.home_incomplete.game_behind_previous,.away_incomplete.game_behind_previous
{
  background-color: #87000D;
}

.home_upcoming,.away_upcoming
{
  background-color: #D9BB8D;
}

.home_ongoing,.away_ongoing
{
  background-color: #549F7D;
}


body
{
  margin: 0px;
  padding: 0px;
  background: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12pt;
  font-weight: 300;
  color: #000000;
}

h1, h2, h3
{
  margin: 0;
  padding: 0;
}

p, ol, ul
{
  margin-top: 0;
}

div#game_details
{
  display: inline-block;
  position:fixed;
  top: 45px;
  left:1205px;
  width: 400px;
}

div.game_detail
{
  position:absolute;
  top:0px;
  left:0px;
  text-align: left;
  width: 100%;
}

div.detail_date
{
  position:relative;
  width:100%;
}

div.detail_headline div
{
  display:inline-block;
  width: 100%;
}

div.detail
{
  width:100%;
}

div.detail div
{
  display:inline-block;
  width: auto;
}

div.detail div.detail_home{
  border-bottom: 1px solid red;
}

div.detail div.detail_score
{
  padding-left: 5px;
  padding-right: 5px;
}

div.team div
{
  display:inline-block;
}

div.team div.name
{
  width: 100px;
}

div.team div.record, div.team div.home_record, div.team div.away_record
{
  width: 75px;
}

div.team div.record.record_with_row, div.team div.home_record.record_with_row, div.team div.away_record.record_with_row
{
  width: 100px;
}

div.team div.pts, div.team div.home_pts, div.team div.away_pts
{
  width: 25px;
}

.divisions
{
  padding-left:20px;
}

.teams
{
  padding-left:10px;
}

div.team .icon
{
  position:relative;
  width:10px;
}

div.icon_mark
{
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


div.team .name, div.team .record, div.team .home_record, div.team .away_record, div.team .pts, div.team .home_pts, div.team .away_pts, div.team .icon
{
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

div#league
{
  position:absolute;
  top:15px;
  left:0px;
  width:1200px;
  z-index: 5;
}

div.conference > div.name
{
  padding:5px 0px 5px 0px;
}

.hovered_bout
{
  background-color: #e0e0e0;
}

.hovered_bout.home_upcoming,.hovered_bout.away_upcoming
{
  background-color: #F2DBB6;
}

.hovered_bout.home_ongoing,.hovered_bout.away_ongoing
{
  background-color: #6BB392;
}

svg.hovered_game, svg.home_upcoming.hovered_game, svg.away_upcoming.hovered_game,svg.incomplete_game.hovered_game,svg.home_ongoing.hovered_game, svg.away_ongoing.hovered_game
{
  background-color: #A2FBA2;
}

svg.todays_game rect{
  stroke: #e07b00;
  stroke-width: 2px !important;
}
svg.tomorrows_game rect{
  stroke: #1effbb;
  stroke-width: 2px !important;
}
svg.yesterdays_game rect{
  stroke: #9a16ff;
  stroke-width: 2px !important;
}


